:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._btlr-12px{border-top-left-radius:12px;}
:root ._btrr-12px{border-top-right-radius:12px;}
:root ._bbrr-12px{border-bottom-right-radius:12px;}
:root ._bblr-12px{border-bottom-left-radius:12px;}
:root ._gap-12px{gap:12px;}