:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._pr-10px{padding-right:10px;}
:root ._gap-t-space-2{gap:var(--t-space-2);}
:root ._fd-row{flex-direction:row;}
:root ._ac-flex-start{align-content:flex-start;}
:root ._gap-0px{gap:0px;}
:root ._gap-8px{gap:8px;}
:root ._w-72px{width:72px;}
:root ._fs-1{flex-shrink:1;}