:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._w-10037{width:100%;}
:root ._pos-relative{position:relative;}
:root ._t-0px{top:0px;}
:root ._zi-999{z-index:999;}
:root ._jc-center{justify-content:center;}