:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._bg-white{background-color:white;}
:root ._ai-center{align-items:center;}
:root ._jc-space-aroun100{justify-content:space-around;}
:root ._gap-3px{gap:3px;}