:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._w-10037{width:100%;}
:root ._bg-FFFFFF35{background-color:#FFFFFF;}
:root ._zi-99{z-index:99;}
:root ._pos-relative{position:relative;}
:root ._pr-12px{padding-right:12px;}
:root ._pl-12px{padding-left:12px;}
:root ._pb-10px{padding-bottom:10px;}
:root ._fd-row{flex-direction:row;}
:root ._bg-c-color-pur1319873852{background-color:var(--c-color-pureWhite);}
:root ._ai-center{align-items:center;}
:root ._h-50px{height:50px;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._pt-4px{padding-top:4px;}
@media (hover) {:root  ._cur-0hover-pointer:hover{cursor:pointer !important;}}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}