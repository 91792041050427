:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._w-10037{width:100%;}
:root ._pt-15px{padding-top:15px;}
:root ._pb-5px{padding-bottom:5px;}
:root ._pr-15px{padding-right:15px;}
:root ._pl-15px{padding-left:15px;}
@media (hover) {:root  ._cur-0hover-pointer:hover{cursor:pointer !important;}}
:root ._bg-c-color-bas1319873852{background-color:var(--c-color-baseWhite);}
:root ._btlr-10px{border-top-left-radius:10px;}
:root ._btrr-10px{border-top-right-radius:10px;}
:root ._bbrr-10px{border-bottom-right-radius:10px;}
:root ._bblr-10px{border-bottom-left-radius:10px;}
:root ._pb-15px{padding-bottom:15px;}
:root ._gap-5px{gap:5px;}