:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._bg-c-color-opa1511904733{background-color:var(--c-color-opaquePurple);}
:root ._gap-5px{gap:5px;}
:root ._btlr-7px{border-top-left-radius:7px;}
:root ._btrr-7px{border-top-right-radius:7px;}
:root ._bbrr-7px{border-bottom-right-radius:7px;}
:root ._bblr-7px{border-bottom-left-radius:7px;}
:root ._pt-6px{padding-top:6px;}
:root ._pb-6px{padding-bottom:6px;}
:root ._pr-10px{padding-right:10px;}
:root ._pl-10px{padding-left:10px;}
:root ._ai-center{align-items:center;}
@media (hover) {:root  ._bg-0hover-c-color-pur1948840147:hover{background-color:var(--c-color-purpleHover) !important;}}
@media (hover) {:root  ._cur-0hover-pointer:hover{cursor:pointer !important;}}