:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._btlr-100000px{border-top-left-radius:100000px;}
:root ._btrr-100000px{border-top-right-radius:100000px;}
:root ._bbrr-100000px{border-bottom-right-radius:100000px;}
:root ._bblr-100000px{border-bottom-left-radius:100000px;}
:root ._pt-15px{padding-top:15px;}
:root ._pr-15px{padding-right:15px;}
:root ._pb-15px{padding-bottom:15px;}
:root ._pl-15px{padding-left:15px;}
:root ._aspectRatio-1{aspect-ratio:1;}
:root ._bg-c-color-pur1319873852{background-color:var(--c-color-pureWhite);}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btc-c-color-neu1067183639{border-top-color:var(--c-color-neutral200);}
:root ._brc-c-color-neu1067183639{border-right-color:var(--c-color-neutral200);}
:root ._bbc-c-color-neu1067183639{border-bottom-color:var(--c-color-neutral200);}
:root ._blc-c-color-neu1067183639{border-left-color:var(--c-color-neutral200);}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}