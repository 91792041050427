:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-column{flex-direction:column;}
:root ._mt-8px{margin-top:8px;}
:root ._mr-8px{margin-right:8px;}
:root ._mb-8px{margin-bottom:8px;}
:root ._ml-8px{margin-left:8px;}
:root ._w-100px{width:100px;}
:root ._h-107px{height:107px;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._btlr-8px{border-top-left-radius:8px;}
:root ._btrr-8px{border-top-right-radius:8px;}
:root ._bbrr-8px{border-bottom-right-radius:8px;}
:root ._bblr-8px{border-bottom-left-radius:8px;}
:root ._bg-c-color-pur1319873852{background-color:var(--c-color-pureWhite);}
:root ._bxsh-0--5px2px5pxv791593429{box-shadow:0.5px 2px 5px var(--c-color-translucentBlack25);}
:root ._h-69px{height:69px;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._bg-c-color-bri10386614{background-color:var(--c-color-brightGray);}
:root ._pt-10px{padding-top:10px;}
:root ._pr-10px{padding-right:10px;}
:root ._pb-10px{padding-bottom:10px;}
:root ._pl-10px{padding-left:10px;}
:root ._pt-5px{padding-top:5px;}
:root ._pr-5px{padding-right:5px;}
:root ._pb-5px{padding-bottom:5px;}
:root ._pl-5px{padding-left:5px;}