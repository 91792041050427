:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-absolute{position:absolute;}
:root ._t-40px{top:40px;}
:root ._w-10037{width:100%;}
:root ._h-auto{height:auto;}
:root ._fd-column{flex-direction:column;}
:root ._pt-15px{padding-top:15px;}
:root ._pr-15px{padding-right:15px;}
:root ._pb-15px{padding-bottom:15px;}
:root ._pl-15px{padding-left:15px;}
:root ._bg-FFFFFF35{background-color:#FFFFFF;}
:root ._btlr-2px{border-top-left-radius:2px;}
:root ._btrr-2px{border-top-right-radius:2px;}
:root ._bbrr-14px{border-bottom-right-radius:14px;}
:root ._bblr-14px{border-bottom-left-radius:14px;}
:root ._btc-EDEDED35{border-top-color:#EDEDED;}
:root ._brc-EDEDED35{border-right-color:#EDEDED;}
:root ._bbc-EDEDED35{border-bottom-color:#EDEDED;}
:root ._blc-EDEDED35{border-left-color:#EDEDED;}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._gap-4px{gap:4px;}
:root ._zi-9999{z-index:9999;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._bxsh-0px4px7pxva26674076{box-shadow:0px 4px 7px var(--shadowColor);}