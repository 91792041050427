:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._w-10037{width:100%;}
:root ._ai-center{align-items:center;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._ai-stretch{align-items:stretch;}
:root ._h-4px{height:4px;}
:root ._bg-FFCC5935{background-color:#FFCC59;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._bbc-CE781335{border-bottom-color:#CE7813;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bblr-100px{border-bottom-left-radius:100px;}
:root ._btlr-100px{border-top-left-radius:100px;}
:root ._bg-E7E7E735{background-color:#E7E7E7;}