:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._w-fit-content{width:fit-content;}
:root ._gap-5px{gap:5px;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._fd-column{flex-direction:column;}
:root ._ai-center{align-items:center;}