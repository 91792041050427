:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._w-10037{width:100%;}
:root ._h-180px{height:180px;}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._mt-10px{margin-top:10px;}
:root ._mb-10px{margin-bottom:10px;}
:root ._bg-c-color-bas1319873852{background-color:var(--c-color-baseWhite);}
:root ._mr-15px{margin-right:15px;}
:root ._ml-15px{margin-left:15px;}
:root ._btlr-10px{border-top-left-radius:10px;}
:root ._btrr-10px{border-top-right-radius:10px;}
:root ._bbrr-10px{border-bottom-right-radius:10px;}
:root ._bblr-10px{border-bottom-left-radius:10px;}
:root ._jc-space-betwe3241{justify-content:space-between;}
:root ._pr-12px{padding-right:12px;}
:root ._pl-12px{padding-left:12px;}
:root ._pt-12px{padding-top:12px;}
:root ._pb-12px{padding-bottom:12px;}
:root ._pb-20px{padding-bottom:20px;}
:root ._pl-8px{padding-left:8px;}
:root ._pr-8px{padding-right:8px;}
:root ._gap-t-space-2{gap:var(--t-space-2);}
:root ._gap-t-space-3{gap:var(--t-space-3);}
:root ._w-72px{width:72px;}
:root ._fs-1{flex-shrink:1;}