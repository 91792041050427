:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._w-80px{width:80px;}
:root ._h-50px{height:50px;}
:root ._mih-14px{min-height:14px;}
:root ._miw-14px{min-width:14px;}
:root ._btlr-100000px{border-top-left-radius:100000px;}
:root ._btrr-100000px{border-top-right-radius:100000px;}
:root ._bbrr-100000px{border-bottom-right-radius:100000px;}
:root ._bblr-100000px{border-bottom-left-radius:100000px;}
:root ._pt-0px{padding-top:0px;}
:root ._pr-0px{padding-right:0px;}
:root ._pb-0px{padding-bottom:0px;}
:root ._pl-0px{padding-left:0px;}
:root ._w-14px{width:14px;}
:root ._h-14px{height:14px;}
:root ._maw-14px{max-width:14px;}
:root ._mah-14px{max-height:14px;}
:root ._bg-c-color-sam1863473369{background-color:var(--c-color-samsamBlue);}
:root ._pos-absolute{position:absolute;}
:root ._t-2px{top:2px;}
:root ._r-21px{right:21px;}
:root ._zi-999999{z-index:999999;}