:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._fd-row{flex-direction:row;}
:root ._pos-absolute{position:absolute;}
:root ._zi-998{z-index:998;}
:root ._t-4237{top:42%;}
:root ._r-16px{right:16px;}
:root ._jc-center{justify-content:center;}
:root ._l-16px{left:16px;}
:root ._b-16px{bottom:16px;}
:root ._pos-relative{position:relative;}
:root ._w-10037{width:100%;}
:root ._h-10037{height:100%;}
:root ._dsp-block{display:block;}
:root ._zi-99{z-index:99;}
:root ._bg-0C0C0C3D35{background-color:#0C0C0C3D;}
:root ._pr-9px{padding-right:9px;}
:root ._pl-9px{padding-left:9px;}
:root ._pt-2px{padding-top:2px;}
:root ._pb-2px{padding-bottom:2px;}
:root ._btlr-10px{border-top-left-radius:10px;}
:root ._btrr-10px{border-top-right-radius:10px;}
:root ._bbrr-10px{border-bottom-right-radius:10px;}
:root ._bblr-10px{border-bottom-left-radius:10px;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-FAFAFA35{color:#FAFAFA;}
:root ._ff-f-family{font-family:var(--f-family);}
:root ._fos-15px{font-size:15px;}