:root ._dsp-flex{display:flex;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pb-20px{padding-bottom:20px;}
:root ._mih-36px{min-height:36px;}
:root ._miw-36px{min-width:36px;}
:root ._w-36px{width:36px;}
:root ._h-36px{height:36px;}
:root ._maw-36px{max-width:36px;}
:root ._mah-36px{max-height:36px;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._mb-2px{margin-bottom:2px;}
:root ._fs-1{flex-shrink:1;}
:root ._fg-1{flex-grow:1;}
:root ._fd-row{flex-direction:row;}
:root ._bg-4C6EEC35{background-color:#4C6EEC;}
:root ._pt-5px{padding-top:5px;}
:root ._pb-5px{padding-bottom:5px;}
:root ._pr-10px{padding-right:10px;}
:root ._pl-10px{padding-left:10px;}
:root ._gap-5px{gap:5px;}
:root ._btlr-10px{border-top-left-radius:10px;}
:root ._btrr-10px{border-top-right-radius:10px;}
:root ._bbrr-10px{border-bottom-right-radius:10px;}
:root ._bblr-10px{border-bottom-left-radius:10px;}
:root ._jc-space-betwe3241{justify-content:space-between;}